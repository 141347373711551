import React from 'react'
import { ConfirmationContainer } from 'tf-checkout-react'

import Head from '../../components/head/index'
import Layout from '../../components/layout'
import { setTfCheckoutReactConfigs } from '../../utils/tf-checkout-config'
import './checkout-complete.scss'

const style = {
  textDecoration: 'none',
  color: 'inherit',
}
const confirmationLabels = {
  confirmationTitle: 'Your order has been confirmed!',
  confirmationMain: (
    <>
      Check the email address you provided for your order confirmation, along with your PDF ticket. You’ll be asked to
      present your ticket at the venue to receive your wristband. If you misplace your order confirmation email or PDF
      ticket, you may view your order and reprint your PDF ticket at any time by visiting the{' '}
      <a href="/orders" style={style}>
        <b>My Tickets </b>
      </a>
      section.
    </>
  ),
  confirmationHelper: (
    <span>
      <br />
      If you have any questions about your order, please contact{' '}
      <a style={{ color: 'inherit' }} href="mailto:tickets@manacommon.com">
        tickets@manacommon.com
      </a>
    </span>
  ),
}

setTfCheckoutReactConfigs()
const CheckoutCompletePage = () => (
  <>
    <Head title="Confirmation Page" />
    <Layout>
      <div className="checkout-complete-container">
        <ConfirmationContainer
          referralPromotions={[]}
          shareButtons={[]}
          shareLink={''}
          isReferralEnabled={true}
          showDefaultShareButtons={true}
          hasCopyIcon={false}
          confirmationLabels={confirmationLabels}
          clientLabel="Mana Common"
          showCopyInfoModal={true}
          showPricingNoteSection={true}
        />
      </div>
    </Layout>
  </>
)

export default CheckoutCompletePage
